<script setup lang="ts">
import type { RouterOutput } from '@eliah/trpc'
import { normalizeURL } from 'ufo'
import { computed } from 'vue'

const props = withDefaults(defineProps<{
  bgRoueUrl?: string
}>(), {
  bgRoueUrl: '/img/eliah_picto-violetclair.png',
})

const trpc = useTrpc()

const { data } = await useLazyAsyncData(() => {
  try {
    return trpc.blog.getPosts.query({
      limit: 4,
    })
  }
  catch (e) {
    console.error(e)
    throw e
  }
})

const $img = useImage()

// Typescript doesn't like prisma json value (recursive type), so we make those json value type any to avoid ts errors
type BlogPost = Omit<NonNullable<RouterOutput['blog']['getPosts']['blogPosts']>[number], 'description' | 'content'> & { description: any, content: any }
const blogPosts = computed(() => (data.value?.blogPosts ?? []) as unknown as BlogPost[])
const articles = computed(() => blogPosts.value.map((blogPost) => {
  return {
    ...blogPost,
    previewImageUrl: $img(normalizeURL(blogPost.previewImageUrl), {
      format: 'webp',
      quality: 80,
      height: 400,
    }),
  }
}))

const cssBgRoueUrl = computed(() => `url(${$img(props.bgRoueUrl, {
  format: 'webp',
  quality: 80,
  height: 400,
})})`)
</script>

<template>
  <div class="relative w-full flex flex-col items-center justify-center bg-[#F6F4F9] py-20">
    <h1 class="mb-16 text-center text-2xl">
      <span class="text-lavender tracking-widest font-gravity font-ubuntu uppercase">Découvrez nos</span><br>
      <span class="text-void font-laviossa">derniers articles</span>
    </h1>

    <div class="bgwhite z-10 grid grid-cols-1 gap-4 container md:grid-cols-2 xl:grid-cols-4">
      <NuxtLink v-for="blogPost in articles" :key="blogPost.id" class="group mx-auto block max-w-70 w-full" :to="`/articles/${blogPost.id}`">
        <div
          class="h-100 w-full shadow-md transition-all group-hover:shadow-md"
          :style="{
            backgroundImage: `url(${blogPost.previewImageUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
          }"
        >
          <div class="h-full w-full bg-void bg-opacity-0 transition-all group-hover:bg-opacity-20" />
        </div>

        <div class="w-full flex items-center justify-center -mt-40">
          <div class="h-60 w-4/5 flex flex-col items-center justify-between bg-white shadow-md transition-all group-hover:bg-lavender group-hover:shadow-lg">
            <div class="h-10 w-[3px] bg-sunset" />
            <h2 class="text-center text-lg">
              <span class="text-void font-laviossa transition-all group-hover:text-white">{{ blogPost.title }}</span>
            </h2>
            <VBtnIcon icon="i-ic:round-plus" theme="primary" size="xl" class="mb-8 mt-7 block" />
          </div>
        </div>
      </NuxtLink>

      <div class="col-span-1 mt-10 flex items-center justify-center md:col-span-2 xl:col-span-4">
        <VBtn theme="void" to="/articles">
          <span class="text-md tracking-widest font-ubuntu">Voir tous nos articles</span>
        </VBtn>
      </div>
    </div>

    <div class="absolute inset-0 flex items-end justify-end lt-md:hidden">
      <div
        :style="{
          backgroundImage: `${cssBgRoueUrl}, ${cssBgRoueUrl}`,
          backgroundSize: '600px, 600px',
          backgroundPosition: '-10% 130%, 70% 130%',
        }"
        class="h-60% w-80% rounded-tl-100 bg-void bg-void bg-no-repeat"
      >
        <div class="h-full w-full rounded-tl-100 bg-void bg-opacity-95" />
      </div>
    </div>
  </div>
</template>
